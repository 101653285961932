<template>
	<li
		:class="['is-' + item.slug, {'is-active': item.name === erathem.name}]"
	>
		<button
			type="button"
			:disabled="count === 0"
			@click="changeFilter('erathem', item)"
		>
			<span>{{ item.name }}</span>
			<span
				v-if="count"
				class="count"
			>
				{{ count }}
			</span>
		</button>
	</li>
</template>

<script>
import {mapState} from 'vuex';
import {FilterMixin} from '@/mixins';


export default {
	mixins: [FilterMixin],
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapState(['erathem']),
		count() {
			return this.$store.getters.getAnimalsByErathem(this.item).length;
		}
	}
};
</script>
